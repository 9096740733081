let lg = {
  App: {
    L_feeders_Selected: 'feeder',
    L_feeder: 'feeder',
    L_componentsTypes_transformer: 'transformer',
    L_componentsTypesLoadBreakSwitch: 'LBS',
    L_componentsTypesMediumVoltageFuse: 'Fuse',
    L_componentsTypes_Sectionalizer: 'Sectionalizer',
    L_componentsTypesRingMainUnit: 'RMU',
    L_componentsTypes_Recloser: 'Recloser',
    L_componentsTypes_voltageRegulator: 'voltage regulator',

    L_modes_LBS_normally_closed: 'Normally Closed',
    L_modes_LBS_normally_open: 'Normally Open',
    L_modes_LBSSelected: 'Selected mode',

    Lstatus_LBS_Open: 'Open',
    Lstatus_LBS_Closed: 'Closed',
    L_status_LBSSelected: 'Selected Status',

    L_componentFunction_main: 'Main',
    L_componentFunction_Isolation: 'Isolation',
    L_componentFunction_sub: 'Sub',
    L_componentFunction_link: 'Link',

    L_Function: 'Selected Function',

    L_connect_with_component: 'Link with feeder',
    L_associatedNotcomponent: 'No link',

    L_associated_with_component: 'Link with component',
    L_associated_with_componentSelected: 'Selected component',

    L_Selected_capacity_Fuse: 'fuse capacity',

    L_Added_successfully: 'Added successfully',

    L_Edited_successfully: 'Edited successfully',
    L_Failed_add: 'Failed to add',

    L_type_of_search: 'Selected type search',

    L_Search_by_number: 'Search by number',
    L_location_search: 'location search',

    L_distance: 'distance',
    L_km: 'Km',
    L_delete: 'delete',
    L_edit: 'Edit',
    L_Action: 'Action',

    L_component_type_search: 'component type search',

    L_Selected_least_one_component: 'Selected at least one component',

    L_location: 'Location',
    L_meter_has_bound_connected_before: 'The meter has been bound to the transformer before',
    L_Confirm_deletion: 'Do you want to delete the component ?',
    L_Yes: 'Yes',
    L_No: 'No',
    L_Create_account: 'Create an account',

    L_language: 'اللغة',
    L_title_Search: 'Search',
    L_button_Search: 'Search',
    L_Enter_account: 'Contract,subscription,Meter,Installation',
    L_Installation: 'Installation',
    L_Close: 'Close',
    L_maxDigits: 'The account Contract field must contain exactly 11 digits',
    L_Subscription: 'Subscription',
    L_MeterNumber: 'Meter Number',
    L_Breaker: 'Breaker',
    L_BreakerSelected: 'Selected cutter capacity',
    L_ContractAccount: 'Contract number',
    L_Coordinates: 'coordinates',
    L_NoData: 'No data found',
    L_windowOpen: 'Open in Maps',
    L_searchField: 'search field',
    L_accuracy: 'Accuracy',
    L_getCoordinates: 'Geolocation request',
    L_PERMISSION_DENIED: 'User denied the request for Geolocation.',
    L_POSITION_UNAVAILABLE: 'Location information is unavailable.',
    L_TIMEOUT: 'The request to get user location timed out.',
    L_UNKNOWN_ERROR: 'An unknown error occurred.',
    L_IsNotSupported: 'Geolocation is not supported by this browser.',
    L_ServerDownView: 'Page not found',
    // --------------------------------------------------------------
    L_Mobile_number: 'Mobile number',
    L_Enter_Mobile: 'Enter the mobile number',
    L_title_SignUpView: 'Sign up',
    L_Enter_username: 'Enter the Name',
    L_username: 'user name',
    L_button_SignUp: 'Sign up',
    L_title_Unauthorized: 'Unauthorized',
    L_401: 'Unauthorized access',
    L_LoginView: 'Login',
    L_password: 'password',
    L_Enter_password: 'Enter password',
    L_passwordConfirmation: 'password Confirmation',
    L_Enter_passwordConfirmation: 'Enter password Confirmation',
    L_passwords_not_match: 'Your passwords do not match.',
    L_Mobile_not_valid: 'Mobile number is not valid',
    L_button_Login: 'Login',
    L_title_feeder: 'feeders',
    L_Name: 'Name',

    L_components: 'number of components [N]',
    L_Show: 'Show',
    L_Capacity: 'Capacity',
    L_Capacity: 'Capacity [KVA]',
    L_meters: 'meters',
    L_error_connection: 'An error occurred during the connection',
    L_Account_created: 'Account successfully created',
    L_title_transformers: 'transformers',
    L_User_already_exists: 'User account already exists',
    L_title_Users: 'Users',
    L_IsActivated: 'User activation',
    L_password_weak: ' password weak',
    L_Successfully_logged: 'Successfully logged in',
    L_Wrong_mobile_password: 'Wrong mobile number or password',

    L_Already_account: 'Already have an account? ',
    L_Enter_Full_Name: 'Enter the full name',
    L_User_permissions_successfully: 'User permissions modified successfully',
    L_User_permissions_Failed: 'Failed to modify user permissions',
    L_P_meter_Show: 'meter Show',
    L_P_meter_Edit: 'meter Edit',
    L_P_Components_Show: 'Components Show',
    L_P_Components_Edit: 'Components Edit',
    L_P_feeder_Show: 'feeder Show',
    L_P_feeder_Edit: 'feeder Edit',
    L_title_components: 'Components',
    L_feeder_Tree: 'Tree',
    L_outputVoltage_high: 'voltage [K]',
    L_outputVoltage_low: 'voltage',
    L_Component_type: 'component type',
    L_transformer: 'transformer',
    L_title_Dashboard: 'dashboard',
    L_title_Users_devices: 'Users devices',
    L_device: 'device',
    L_title_meters: 'meters',
    L_title_meter_coordinate_update: 'coordinate update',
    L_meter_Update_info: 'Be close to the meter, then press the Update button',
    L_Update_button: 'Update',
    L_WaitingAccuracyImprove: 'Waiting for the accuracy to improve',

    L_feeders_Selected_All: 'All',
    L_permission: 'permission',
    L_Status: 'Status',
    L_save: 'Save',

    L_meterSearch: 'Searching for meters',
    L_meterAdd: 'Add meter data',
    L_meterEdit: 'Edit meter data',

    L_meterUpdateLocation: 'Update meter location data',
    L_metersDisplay: 'View all meters',
    L_meterRelationship: 'Connecting meter to transformer',

    L_feederDisplay: 'View all feeders',
    L_feederEdit: 'Edit feeder data',
    L_feederAdd: 'Add feeder data',
    L_componentDisplay: 'View all components',
    L_componentEdit: 'Edit component data',
    L_componentAdd: 'Add component data',
    L_componentsDel: 'Delete component data',

    L_Cannot_delete: 'Cannot delete component used',
    L_kml: 'KML',
    L_component_bound_meters: 'component bound meters',

    L_Meter_not_valid: 'The Meter is incorrect',
    L_bound_meter: 'bound meter',

    L_meter_bound_info: 'Be close to the meter, then press the bound meter button',
    L_signOut: 'sign out',

    L_Selected_type_component: 'Selected type component',
    L_Capacity_Unknown: 'Unknown',

    L_Selected_capacity_transformer: 'Selected capacity',
    L_kVA: 'kVA',
    L_Voltage: 'Volte',
    L_Ampere: 'Ampere',
    L_lowVoltageSelected: 'Selected voltage',

    L_ComponentsButtonAdd: 'Add component',
    // L_ComponentsButtonAdd: 'Add component',
    L_associatedWithComponent: 'associated with component',

    L_description: 'description',
    L_componentName: 'Name',
    L_componentName_Enter_numbers_only: 'Enter only positive numbers',
    L_Latitude: 'Latitude [N]',
    L_Longitude: 'Longitude [E]',

    L_coordinate_request: 'Gps',
    L_coordinate_incorrect: 'The coordinate is incorrect',
    L_Edit_user_permissions: 'Edit user permissions',
    L_Name_already_exists: 'There is an component with the same number',

    L_location_meter_successfully_updated: 'The location of the meter has been successfully updated',

    L_Failed_update_location_meter: 'Failed to update the location of the electric meter',
    L_meter_location_was_updated:"The counter location was previously updated",
    L_title_change_password: 'change the password',
    L_Enter_OldPassword: 'Enter Old Password',

    L_Enter_NewPassword: 'Enter New Password',
    L_Enter_NewPasswordConfirmation: 'Enter New Password Confirmation',

    L_old_password_incorrect: 'The password is incorrect',

    L_Password_changed_successfully: 'Password changed successfully',

    L_title_statistics: 'Statistics',
    L_components_count: 'components count entered',
    L_meters_count: 'meters count entered',
    L_Total: 'Total',

    L_title_MeterDataUpdate: 'Meter data update',
    L_Address: 'Address',

    L_Al_Shamly: 'Al Shamly',
    L_Hafeera: 'Al Hafeera',
    L_asbatar: 'Asbatar',
    L_eamayir_bn_sanea: 'Eamayir Bn Sanea',
    L_West_Shamli: 'West Al Shamli',
    L_alfayda: 'Al Alfayda',
    L_libada: 'Libada',
    L_East_Shamli: 'East Al Shamli',

    L_libada: 'Libada',
    L_Al_Bidi: 'Al Bidi',
    L_Al_bardan: 'Al Bardan',
    L_Al_mase: 'Al mase',
    L_Dharghat: 'Dharghat',
    L_Al_shaqa: 'Al Shaqa',

    L_nothing: '',

    L_market: 'Market',
    L_Shamly: 'Al Shamly',
    L_Deira: 'Al Deira',
    L_altaeawun: 'Al Taeawun',
    L_Al_Rayyan: 'Al Rayyan',
    L_alsinaeia: 'Al Sinaeia',
    L_alnakhil_alsharqiu: 'Eastern_palm',
    L_alrafiea: 'Al Rafiea',
    L_hafirat_alsabea: 'Hafirat Al Sabea',
    L_alnakhil_shamal: 'North palm',
    L_alnakhil_janub: 'Southern palm',

    L_rawdat_altinihat: 'Rawdat Al Tinihat',
    L_mukhatat_aleawda: 'Scheme Al Eawda',
    L_hafayir_aleahin: 'Hafayir Al eahin',
    L_dafayin_aldaba: 'Dafayin Al daba',
    L_bir_alrafdi: 'Bir Al rafdi',
    L_muhayirat_dhuayra: 'Muhayirat Dhuayra',
    L_albuaytan_naeimin: '[Al Buaytan] [Naeimin]',
    L_ghizlana: 'Ghizlana',
    L_am_nakhlat_qumraa: '[Am Nakhlat] [Qumraa]',
    L_almazarie: 'Al Mazarie',
    L_tafhan: 'Tafhan',
    L_qalib_alatrim: 'Qalib Al Atrim',
    L_bayda_alnathil: 'Bayda Al Nathil',
    L_alabitar: 'Al Abitar',
    L_sahot: 'Sahot',
    L_alzabya: 'Al Zabya',
    L_mushid: 'Mushid',
    L_safia: 'Safia',
    L_alhafirat_algharbia: 'Al Hafirat Al Gharbia',
    L_mazarie_alhania: 'Mazarie Al Hania',
    L_almukhatat: 'Al Mukhatat',
    L_alqadima: 'Al Qadima',
    L_aljadiduh: 'Al Jadiduh',
    L_badayie_bn_shitywiin: 'Badayie Bn Shitywiin',
    L_mubriz:"Mubriz",
    L_bir_bin_manwa:"Bir Bin Manwa",
    L_musharifa:"Musharifa",
    L_vip: 'Vip',


    L_coordinate_sharing:"coordinate sharing",
    
    L_data_incorrect:"The data entered is incorrect",


L_Search_result_for:"Search result for",
L_Update_subscriptions:"Update subscriptions",
L_meter_coordinates_updated_count:'meters count updated location',

L_Update_all_data:"Update all data",
L_Update_coordinates_installation :"Update coordinates with installation",

L_title_Search_history:"Search history",

L_field:"search field",
L_search_data:"search data",
L_time:"time",
L_Delete_login_history:"Delete",


L_day_name_0:"Sunday",
L_day_name_1:"Monday",
L_day_name_2:"Tuesday",
L_day_name_3:"Wednesday",
L_day_name_4:"Thursday",
L_day_name_5:"Friday",
L_day_name_6:"Saturday",

L_date:"date",
L_day:'day',
L_searches_count:"searches count",
L_Download_regions_file:"Download the regions file",


  },


  


};

export default lg;
