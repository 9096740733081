import { createStore } from 'vuex';
import router from '@/router.js';
import { redirectionName as re } from '@/lib/constants';
export default createStore({
  state: {
    signOut: false,
    IsAdmin: false,
    IsAdmin_1: false,
    IsActivated: false,
    meterEdit: false,
    meterAdd: false,
    meterSearch: false,
    meterUpdateLocation: false,
    metersDisplay: false,
    meterRelationship: false,
    //----------------------------
    feederDisplay: false,
    feederEdit: false,
    feederAdd: false,
    //----------------------------
    componentsDisplay: false,
    componentsEdit: false,
    componentsAdd: false,
    componentsDel: false,
    feeder_Tree: false,

    //----------------------------
    showLoading: false,

    title: '',
    meta_title: '',
  },
  mutations: {
    IsAdmin(state, value) {
      state.IsAdmin = value;
    },
    IsAdmin_1(state, value) {
      state.IsAdmin_1 = value;
    },
    signOut(state, value) {
      state.signOut = value;
    },
    IsActivated(state, value) {
      state.IsActivated = value;
    },
    //----------------------------
    meterEdit(state, value) {
      state.meterEdit = value;
    },

    meterAdd(state, value) {
      state.meterAdd = value;
    },
    meterSearch(state, value) {
      state.meterSearch = value;
    },
    meterUpdateLocation(state, value) {
      state.meterUpdateLocation = value;
    },
    metersDisplay(state, value) {
      state.metersDisplay = value;
    },
    meterRelationship(state, value) {
      state.meterRelationship = value;
    },
    //----------------------------
    feederDisplay(state, value) {
      state.feederDisplay = value;
    },
    feederEdit(state, value) {
      state.feederEdit = value;
    },
    feederAdd(state, value) {
      state.feederAdd = value;
    },
    //----------------------------
    componentsDisplay(state, value) {
      state.componentsDisplay = value;
    },
    componentsEdit(state, value) {
      state.componentsEdit = value;
    },
    componentsAdd(state, value) {
      state.componentsAdd = value;
    },
    componentsDel(state, value) {
      state.componentsDel = value;
    },
    feeder_Tree(state, value) {
      state.feeder_Tree = value;
    },

    showLoading(state, value) {
      state.showLoading = value;
    },
    title(state, value) {
      state.title = value;
    },
    meta_title(state, value) {
      state.meta_title = value;
    },
  },
  actions: {},
  modules: {},
  getters: {
    IsAdmin(state) {
      return state.IsAdmin;
    },
    IsAdmin_1(state) {
      return state.IsAdmin_1;
    },

    IsActivated(state) {
      return state.IsActivated;
    },
    //----------------------------
    meterEdit(state) {
      return state.meterEdit;
    },

    meterAdd(state) {
      return state.meterAdd;
    },
    meterSearch(state) {
      return state.meterSearch;
    },
    meterUpdateLocation(state) {
      return state.meterUpdateLocation;
    },
    metersDisplay(state) {
      return state.metersDisplay;
    },
    meterRelationship(state) {
      return state.meterRelationship;
    },
    //----------------------------
    feederDisplay(state) {
      return state.feederDisplay;
    },
    feederEdit(state) {
      return state.feederEdit;
    },
    feederAdd(state) {
      return state.feederAdd;
    },
    //----------------------------
    componentsDisplay(state) {
      return state.componentsDisplay;
    },
    componentsEdit(state) {
      return state.componentsEdit;
    },
    componentsAdd(state) {
      return state.componentsAdd;
    },
    componentsDel(state) {
      return state.componentsDel;
    },
    feeder_Tree(state) {
      return state.feeder_Tree;
    },

    signOut(state) {
      return state.signOut;
    },
    showLoading(state) {
      return state.showLoading;
    },

    title(state, value) {
      return state.title;
    },

    meta_title(state, value) {
      return state.meta_title;
    },
  },
});
