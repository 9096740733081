<template>
  <!-- {{$store.getters.baseURL}} -->

  <!-- <BeforeinstallpromptView></BeforeinstallpromptView> -->
  <div v-if="loading" class="container" >
    <nav class="navbar navbar-expand-lg  " >
      <div class="container-fluid">
        <!-- <a class="navbar-brand" href="#">Navbar</a> -->
        <div id="v-model-select">
          <select v-model="lang" @change="ev_locale($event)" class="form-select form-select-sm">
            <option disabled value="">{{ $t('App.L_language') }}</option>
            <option class="dropdown-item" value="ar">العربية</option>
            <option class="dropdown-item" value="en">English</option>
          </select>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="visible = !visible"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" :class="!visible ? 'collapse' : ''" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                :to="{ name: 'SignUpView' }"
                v-if="$store.getters.IsActivated == 10"
                @click="visible = !visible"
                class="nav-link"
                active-class="active"
                exact
                >{{ $t('App.L_title_SignUpView') }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'LoginView' }"
                v-if="$store.getters.IsActivated == 10"
                @click="visible = !visible"
                class="nav-link"
                active-class="active"
                exact
                >{{ $t('App.L_LoginView') }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'MetersView' }"
                v-if="$store.getters.meterSearch == true || $store.getters.meterUpdateLocation"
                @click="visible = !visible"
                class="nav-link"
                active-class="active"
                exact
                >{{ $t('App.L_title_meters') }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'feederView' }"
                v-if="$store.getters.feederDisplay == true"
                @click="visible = !visible"
                class="nav-link"
                active-class="active"
                exact
                >{{ $t('App.L_title_feeder') }}</router-link
              >
            </li>
            <!-- <li class="nav-item">
                          <router-link
                            :to="{ name: 'ComponentsView' }"
                            v-if="$store.getters.componentsDisplay == true"
                            @click="visible = !visible"
                            class="nav-link"
                            active-class="active"
                            exact
                            >{{ $t('App.L_title_components') }}</router-link
                          >
                        </li> -->
            <li class="nav-item">
              <router-link
                :to="{ name: 'DashboardView' }"
                v-if="$store.getters.IsAdmin == true"
                @click="visible = !visible"
                class="nav-link"
                active-class="active"
                exact
                >{{ $t('App.L_title_Dashboard') }}</router-link
              >
            </li>
            <li class="nav-item">
              <a href="#signOut" v-if="$store.getters.signOut == true" @click="signOut" class="nav-link">{{ $t('App.L_signOut') }}</a>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'ChangePasswordView' }"
                v-if="$store.getters.signOut == true"
                @click="visible = !visible"
                class="nav-link"
                active-class="active"
                exact
                >{{ $t('App.L_title_change_password') }}</router-link
              >
            </li>

            <li class="nav-item dropdown"></li>
          </ul>
        </div>
      </div>
    </nav>

    <router-view />
    <!-- ($route.name == 'Login' && socket.connected == false) -->
  </div>
  <LoadingView v-show="$store.getters.showLoading === true"></LoadingView>
</template>

<script>
import BeforeinstallpromptView from '@/components/BeforeinstallpromptView.vue';

import store from '@/store.js';
import { useI18n } from 'vue-i18n';
import { HTTP } from '@/lib/http-common';
import { setLocale } from 'yup';
import { en, ar } from '@/lib/yup-locales.esm';
import { setItem, getItem, isItemAvailable } from './lib/MySessionStorage';
import LoadingView from '@/components/LoadingView.vue';
export default {
  components: { BeforeinstallpromptView, LoadingView },

  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  data() {
    return {
      lang: 'en',
      loading: false,
      visible: false,
    };
  },

  created() {
    this.loadcss();

    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      console.debug('onpopstate:', 'onpopstate');
      history.go(1);
      store.commit('showLoading', false);
    };
  },
  watch: {
    $route(to, from) {
      document.title = this.$t(to.meta.title_locale); // to.meta.title_locale || "Your Website";
    },

    lang: function (val) {
      this.$i18n.locale = val;

      let htmlEl = document.querySelector('html');

      htmlEl.setAttribute('lang', this.lang);

      if (this.lang == 'ar') {
        htmlEl.setAttribute('dir', 'rtl');
        setLocale(ar);
      } else {
        htmlEl.setAttribute('dir', 'ltr');
        setLocale(en);
      }
    },
  },

  methods: {
    signOut() {
      HTTP.post(`/api/user/signOut`)
        .then((result) => {
          store.commit('signOut', false);
          this.showLoading = false;
          this.$router.push({ name: 'LoginView' });
        })
        .catch((error) => {
          console.error('Error:', error);
          this.showLoading = false;

          this.$toast.show(this.t('App.L_error_connection'), {
            type: 'error', // error  warning  info success
            position: 'bottom', //top
          });
        });
    },
    hied_navbar() {
      this.visible = false;
    },

    ev_locale(event) {
      this.lang = event.target.value;
      setItem('locale', event.target.value);
      this.$router.go(this.$router.currentRoute);
    },

    async loadcss() {
      let Is_locale = isItemAvailable('locale');
      if (Is_locale) {
        this.lang = getItem('locale');

        let htmlEl = document.querySelector('html');

        htmlEl.setAttribute('lang', this.lang);

        if (this.lang == 'ar') {
          htmlEl.setAttribute('dir', 'rtl');
          setLocale(ar);
          await import('./assets/css/bootstrap.rtl.min.css');
          this.loading = true;
        } else if (this.lang == 'en') {
          htmlEl.setAttribute('dir', 'ltr');
          setLocale(en);

          await import('./assets/css/bootstrap.min.css');
          this.loading = true;
        } else {
          await import('./assets/css/bootstrap.rtl.min.css');
          this.loading = true;
        }
      } else {
        await import('./assets/css/bootstrap.min.css');
        this.loading = true;
      }
    },
  },
  async mounted() {},
};
</script>

<style>
/*
     #container {
      width: 70%;
      height:  100%;;
      margin: 30px auto;
      background: #fff;
      padding: 20px;
       box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.05);
    }  */

/* body {
          font-family: var(--bs-font-sans-serif);
          font-size: 10px !important;
          font-weight: 50;
          line-height: 1.5;
          color: #212529;
          -webkit-text-size-adjust: 100%;
        } */
</style>
