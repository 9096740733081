import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuex from 'vuex';

import Toaster from '@meforma/vue-toaster';

window.$ = window.jQuery = require('jquery');
window.Raphael = window.Raphael = require('raphael');

import { Treant } from './lib/Treant/Treant.js';

import './lib/Treant/Treant.css';

import './lib/Treant/vendor/perfect-scrollbar/perfect-scrollbar.css';
import './lib/Treant/vendor/perfect-scrollbar/connectors.css';

import './lib/Treant/vendor/jquery.mousewheel.js';
import './lib/Treant/vendor/perfect-scrollbar/perfect-scrollbar.js';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

import './assets/css/Modal_camera.css';
// import './assets/css/styles.css';
import './assets/css/classColor.css';

// String.prototype.ArtoEn= function() {
//   return this.replace(/[\u0660-\u0669]/, 
//   	d => d.charCodeAt() - 1632)
// }


import './PreferredTheme'





String.prototype.IntoEn= function() {
  return this.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c) {
    return c.charCodeAt(0) & 0xf;
});
}


String.prototype.Remove_Arabic_letters= function() {
  return this.replace(/[\u0621-\u064A]+/g, function (c) {
    return ""
});
}



import ar from './locales/ar.js';
import en from './locales/en.js';

import md5 from 'js-md5';

import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  messages: {
    en: en,
    ar: ar,
  },
  locale: 'en',
  fallbackLocale: 'en',
});

let app = createApp(App);

app.use(Toaster, {
  // One of the options
  position: 'top',
});

app.use(Vuex);
app.use(i18n);
app.use(store);
app.use(router);

app.config.globalProperties.$md5 = md5;
router.app = app;
app.mount('#app');
