import { createRouter, createWebHistory } from 'vue-router';
import { HTTP } from '@/lib/http-common';
import store from '@/store.js';

const routes = [
  {
    path: '/SignUp',
    name: 'SignUpView',

    component: () => import('@/views/user/SignUpView.vue'),
    meta: {
      title_locale: 'App.L_title_SignUpView',
    },
  },

  {
    path: '/Login',
    name: 'LoginView',

    component: () => import('@/views/user/LoginView.vue'),
    meta: {
      title_locale: 'App.L_LoginView',
    },
  },

  {
    path: '/ChangePassword',
    name: 'ChangePasswordView',

    component: () => import('@/views/user/ChangePasswordView.vue'),
    meta: {
      title_locale: 'App.L_title_change_password',
    },
  },

  {
    path: '/Unauthorized',
    name: 'UnauthorizedView',

    component: () => import('@/components/UnauthorizedView.vue'),
    meta: {
      title_locale: 'App.L_title_Unauthorized',
    },
  },
  {
    path: '/feeder',
    name: 'feederView',

    component: () => import('@/views/feederView.vue'),
    meta: {
      title_locale: 'App.L_title_feeder',
    },
  },
  // {
  //   path: '/Components',
  //   name: 'ComponentsView',

  //   component: () => import('@/views/ComponentsView.vue'),
  //   meta: {
  //     title_locale: 'App.L_title_components',
  //   },
  // },

  {
    path: '/Meters',
    name: 'MetersView',

    component: () => import('@/views/meters/MetersView.vue'),
    redirect: { name: 'SearchView' },
    meta: {
      title_locale: 'App.L_title_meters',
    },
    children: [
      {
        path: '/Meters/Search',
        name: 'SearchView',

        component: () => import('@/views/meters/SearchView.vue'),
        meta: {
          title_locale: 'App.L_title_Search',
        },
      },
      {
        path: '/Meters/CoordinateUpdate',
        name: 'CoordinateUpdateView',

        component: () => import('@/views/meters/CoordinateUpdateView.vue'),
        meta: {
          title_locale: 'App.L_title_meter_coordinate_update',
        },
      },
    ],
  },

  {
    path: '/Dashboard',
    name: 'DashboardView',

    component: () => import('@/views/admin/DashboardView.vue'),
    redirect: { name: 'UsersView' },
    meta: {
      title_locale: 'App.L_title_Users',
    },
    children: [
      {
        path: '/Dashboard/Users',
        name: 'UsersView',
        // default:()  => import('@/views/admin/UsersView.vue'),
        component: () => import('@/views/admin/UsersView.vue'),
        meta: {
          title_locale: 'App.L_title_Users',
        },
      },
      {
        path: '/Dashboard/Users/Devices',
        name: 'UsersDevicesView',

        component: () => import('@/views/admin/UsersDevicesView.vue'),
        meta: {
          title_locale: 'App.L_title_Users_devices',
        },
      },
      {
        path: '/Dashboard/Users/Statistics',
        name: 'StatisticsView',

        component: () => import('@/views/admin/StatisticsView.vue'),
        meta: {
          title_locale: 'App.L_title_statistics',
        },
      },
      {
        path: '/Dashboard/Users/MeterDataUpdate',
        name: 'MeterDataUpdateView',

        component: () => import('@/views/admin/MeterDataUpdateView.vue'),
        meta: {
          title_locale: 'App.L_title_MeterDataUpdate',
        },
      },
      {
        path: '/Dashboard/Users/SearchHistory',
        name: 'SearchHistoryView',

        component: () => import('@/views/admin/SearchHistoryView.vue'),
        meta: {
          title_locale: 'App.L_title_Search_history',
        },
      },
    ],
  },

  {
    path: '/:catchAll(.*)',
    // name: 'Main',
    //component: Main
    redirect: { name: 'MetersView' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  var query_length = Object.keys(from.query).length;

  if (query_length > 0) {
    return next();
  }

  let rot = await get_Permissions_user();

  if (rot.includes(to.name) == false && to.name != rot[0]) {
    return next({ name: rot[0] });
  }

  next();
});

async function get_Permissions_user() {
  try {
    store.commit('showLoading', true);

    let result = await HTTP.post(`/api/authentication/get_Permissions_user_id`);

    result = result.data;
    result = result.result;
    // console.log((typeof result.Permissions === 'object'))

    if (typeof result.Permissions === 'object') {
      let pr = result.Permissions;

      store.commit('IsAdmin', pr.IsAdmin);
      store.commit('IsAdmin_1', pr.IsAdmin_1);
      store.commit('IsActivated', pr.IsActivated);
      store.commit('meterEdit', pr.meterEdit);
      store.commit('meterAdd', pr.meterAdd);
      store.commit('meterSearch', pr.meterSearch);
      store.commit('meterUpdateLocation', pr.meterUpdateLocation);
      store.commit('metersDisplay', pr.metersDisplay);
      store.commit('meterRelationship', pr.meterRelationship);
      //---------------------
      store.commit('feederDisplay', pr.feederDisplay);
      store.commit('feederEdit', pr.feederEdit);
      store.commit('feederAdd', pr.feederAdd);
      //---------------------
      store.commit('componentsDisplay', pr.componentsDisplay);
      store.commit('componentsEdit', pr.componentsEdit);
      store.commit('componentsAdd', pr.componentsAdd);
      store.commit('componentsDel', pr.componentsDel);
      store.commit('feeder_Tree', pr.feeder_Tree);

      store.commit('signOut', true);
    }
    // store.commit('showLoading', false);
    if (result.router) {
      return result.router;
    }
  } catch (error) {
    store.commit('showLoading', false);
    console.error('Error:', error);
  }
}

export default router;
