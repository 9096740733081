<template>
  <div v-if="deferredPrompt" class="" style="margin-top: 100px">
    <div class="rounded d-flex justify-content-center">
      <div class="col-sm-8 shadow-lg p-3 bg-light">
        <div class="text-center">
          <button @click="M_dismiss" type="button" class="btn btn-primary">{{ $t('App.L_Dismiss') }}</button>
          <button @click="M_install" type="button" class="btn btn-primary">{{ $t('App.L_Install') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

import * as yup from 'yup';

import { string } from 'yup/lib/locale';
export default {
  name: 'BeforeinstallpromptView',
  components: {},

  setup() {
    const { t, locale } = useI18n();

    return {
      t,
      locale,
    };
  },

  data() {
    return {
      deferredPrompt: null,
    };
  },

  created() {

console.log('beforeinstallprompt')

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      console.log(e)
      this.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async M_dismiss() {
      this.deferredPrompt = null;
    },
    async M_install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>
