


 let mixed = {
  default: '${path} is invalid',
  required: '${path} is a required field',
  oneOf: '${path} must be one of the following values: ${values}',
  notOneOf: '${path} must not be one of the following values: ${values}',
  notType: ({
    path,
    type,
    value,
    originalValue
  }) => {
    let isCast = originalValue != null && originalValue !== value;
    let msg = `${path} must be a \`${type}\` type, ` + `but the final value was: \`${printValue(value, true)}\`` + (isCast ? ` (cast from the value \`${printValue(originalValue, true)}\`).` : '.');

    if (value === null) {
      msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
    }

    return msg;
  },
  defined: '${path} must be defined'
};
 let string = {
  length: '${path} must be exactly ${length} characters',
  min: '${path} must be at least ${min} characters',
  max: '${path} must be at most ${max} characters',
  matches: '${path} must match the following: "${regex}"',
  email: '${path} must be a valid email',
  url: '${path} must be a valid URL',
  uuid: '${path} must be a valid UUID',
  trim: '${path} must be a trimmed string',
  lowercase: '${path} must be a lowercase string',
  uppercase: '${path} must be a upper case string'
};
export let number = {
  min: '${path} must be greater than or equal to ${min}',
  max: '${path} must be less than or equal to ${max}',
  lessThan: '${path} must be less than ${less}',
  moreThan: '${path} must be greater than ${more}',
  positive: '${path} must be a positive number',
  negative: '${path} must be a negative number',
  integer: '${path} must be an integer'
};
 let date = {
  min: '${path} field must be later than ${min}',
  max: '${path} field must be at earlier than ${max}'
};
 let boolean = {
  isValue: '${path} field must be ${value}'
};
 let object = {
  noUnknown: '${path} field has unspecified keys: ${unknown}'
};
 let array = {
  min: '${path} field must have at least ${min} items',
  max: '${path} field must have less than or equal to ${max} items',
  length: '${path} must be have ${length} items'
};













var mixed$4 = {
  "default": '${path} غير صالح.',
  required: '${path} هو حقل مطلوب',
  oneOf: '${path} يجب أن تكون واحدة من القيم التالية: ${values}',
  notOneOf: '${path} لا يجب أن تكون واحدة من القيم التالية: ${values}',
  notType: function notType(_ref) {
    var path = _ref.path,
        type = _ref.type,
        value = _ref.value,
        originalValue = _ref.originalValue;
    var isCast = originalValue != null && originalValue !== value;
    var msg = path + " \u064A\u062C\u0628 \u0623\u0646 \u064A\u0643\u0648\u0646 `" + type + "` \u0646\u0648\u0639, " + ("\u0648\u0644\u0643\u0646 \u0627\u0644\u0642\u064A\u0645\u0629 \u0627\u0644\u0646\u0647\u0627\u0626\u064A\u0629 \u0643\u0627\u0646\u062A \u0641\u064A: `" + printValue(value, true) + "`") + (isCast ? " (\u0627\u0644\u0645\u062F\u0644\u0649 \u0628\u0647\u0627 \u0645\u0646 \u0642\u064A\u0645\u0629 `" + printValue(originalValue, true) + "`)." : '.');

    if (value === null) {
      msg += "\n \u0625\u0630\u0627 \u0643\u0627\u0646 \u0627\u0644\u0645\u0642\u0635\u0648\u062F \"\u0644\u0627\u063A\u064A\u0629\" \u0643\u0642\u064A\u0645\u0629 \u0641\u0627\u0631\u063A\u0629 \u0645\u0645\u0627 \u0644\u0627 \u0634\u0643 \u0641\u064A\u0647 \u0644\u0644\u0627\u062D\u062A\u0641\u0627\u0644 \u0645\u062E\u0637\u0637 \u0643\u0645\u0627" + ' `.nullable()`';
    }

    return msg;
  }
};
var string$4 = {
  length: '${path} يجب أن يكون بالضبط ${length} حرفا',
  min: '${path} يجب أن تكون على الأقل ${min} حرفا',
  max: '${path} يجب أن تكون على الأكثر ${max} حرفا',
  matches: '${path} يجب أن يطابق ما يلي: "${regex}"',
  email: '${path} يجب أن يكون عنوان بريد إلكتروني صالح',
  url: '${path} يجب أن يكون عنوان URL صالحا',
  trim: '${path} يجب أن تكون سلسلة قلص',
  lowercase: '${path} يجب أن تكون سلسلة صغيرة',
  uppercase: '${path} يجب أن تكون سلسلة الحالة العلوي'
};
var number$4 = {
  min: '${path} يجب أن تكون أكبر من أو يساوي ${min}',
  max: '${path} يجب أن يكون أقل من أو يساوي ${max}',
  lessThan: '${path} يجب أن يكون أقل من ${less}',
  moreThan: '${path} يجب أن تكون أكبر من ${more}',
  positive: '${path} يجب أن يكون رقما موجبا',
  negative: '${path} يجب أن يكون رقما سالبا',
  integer: '${path} يجب أن يكون رقما'
};
var date$4 = {
  min: '${path} يجب أن يكون حقل في وقت لاحق من ${min}',
  max: '${path} يجب أن يكون حقل في وقت سابق من ${max}'
};
var _boolean$4 = {};
var object$4 = {
  noUnknown: '${path} حقل لا يمكن أن يكون مفاتيح غير محددة في شكل وجوه'
};
var array$4 = {
  min: 'يجب أن يكون ${path} حقل على الأقل ${min} من العناصر',
  max: '${path} يجب أن يكون الحقل أقل من أو يساوي إلى ${max} من العناصر'
};



export const en = {
  __proto__: null,
  mixed: mixed,
  string: string,
  number: number,
  date: date,
  boolean: boolean,
  object:object,
  array: array
};


export const ar = {
  __proto__: null,
  mixed: mixed$4,
  string: string$4,
  number: number$4,
  date: date$4,
  boolean: _boolean$4,
  object: object$4,
  array: array$4
};




