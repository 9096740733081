export function setItem(key, value) {
  localStorage.setItem(key, value);
}

export function getItem(key) {
  if (localStorage.getItem(key)) {
    return localStorage.getItem(key);
  } else {
    return null;
  }
}

export function isItemAvailable(key) {
  if (localStorage.getItem(key)) {
    return true;
  } else {
    return false;
  }
}
