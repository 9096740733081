// http-common.js
import axios from 'axios';
import store from '@/store.js';
// axios.defaults.withCredentials = true;



export const HTTP = axios.create({
  
  baseURL: process.env.VUE_APP_baseURL,
  // timeout: 2000,
  headers: {
  //  Authorization: 'Bearer {token}'
   'Access-Control-Allow-Origin': '*',
   mode: 'cors',
   credentials: 'include'
  }
})

HTTP.interceptors.request.use(function (config) {
  // Do something before request is sent
  store.commit('showLoading', true);
  return config;
}, function (error) {
  // Do something with request error
  store.commit('showLoading', false);
  return Promise.reject(error);
});

// Add a response interceptor
HTTP.interceptors.response.use(function (response) {
  // console.debug("axios",response.config.url)



if (response.config.url!='/api/authentication/get_Permissions_user_id'){
  store.commit('showLoading', false);
}
store.commit('showLoading', false);


  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  store.commit('showLoading', false);


  return Promise.reject(error);
});


