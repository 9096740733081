let elementNames = {};

let Colors = {Colors:[
  {classColor: 'transformer ' ,Name: ('App.L_componentsTypes_transformer'), value: 1 },
  {classColor: 'LoadBreakSwitch ' ,Name: ('App.L_componentsTypesLoadBreakSwitch'), value: 2 },
  {classColor: 'MediumVoltageFuse ' ,Name: ('App.L_componentsTypesMediumVoltageFuse'), value: 3 },
  {classColor: 'Sectionalizer ' ,Name: ('App.L_componentsTypes_Sectionalizer'), value: 4 },
  {classColor: 'RingMainUnit ' ,Name: ('App.L_componentsTypesRingMainUnit'), value: 5 },
  {classColor: 'Recloser ' ,Name: ('App.L_componentsTypes_Recloser'), value: 6 },
  {classColor: 'voltageRegulator ' ,Name: ('App.L_componentsTypes_voltageRegulator'), value: 7 },
  {classColor: 'feeder ' ,Name: ('App.L_feeder'), value: -1 },
]}


// Object.assign(elementNames, Colors.Colors);
elementNames=Object.assign(elementNames, Colors);

function initialization(t) {
  

 let Names = {
    componentTypesView: [
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypes_transformer'), value: 1 },
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypesLoadBreakSwitch'), value: 2 },
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypesMediumVoltageFuse'), value: 3 },
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypes_Sectionalizer'), value: 4 },
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypesRingMainUnit'), value: 5 },
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypes_Recloser'), value: 6 },
      {Color: '#0d6efd' ,Name: t('App.L_componentsTypes_voltageRegulator'), value: 7 },
    ],

    transformerCapacity: [
      { Name: t('App.L_Capacity_Unknown'), value: -1 },
      { Name: `50   ${t('App.L_kVA')}`, value: 50 },
      { Name: `100  ${t('App.L_kVA')}`, value: 100 },
      { Name: `200  ${t('App.L_kVA')}`, value: 200 },
      { Name: `300  ${t('App.L_kVA')}`, value: 300 },
      { Name: `500  ${t('App.L_kVA')}`, value: 500 },
      { Name: `1000 ${t('App.L_kVA')}`, value: 1000 },
    ],

    lowVoltage: [
      { Name: `220   ${t('App.L_Voltage')}`, value: 1 },
      { Name: `400  ${t('App.L_Voltage')}`, value: 3 },
    ],

    BreakerCapacity: [
      { Name: `20   ${t('App.L_Ampere')}`, value: 20 },
      { Name: `30  ${t('App.L_Ampere')}`, value: 30 },
      { Name: `40  ${t('App.L_Ampere')}`, value: 40 },
      { Name: `50  ${t('App.L_Ampere')}`, value: 50 },
      { Name: `60  ${t('App.L_Ampere')}`, value: 60 },
      { Name: `70  ${t('App.L_Ampere')}`, value: 70 },
      { Name: `100  ${t('App.L_Ampere')}`, value: 100 },
      { Name: `150  ${t('App.L_Ampere')}`, value: 150 },
      { Name: `200  ${t('App.L_Ampere')}`, value: 200 },
      { Name: `400  ${t('App.L_Ampere')}`, value: 400 },
    ],

    modes_LBS: [
      { Name: t('App.L_modes_LBS_normally_closed'), value: 1 },
      { Name: t('App.L_modes_LBS_normally_open'), value: 2 },
    ],

    status_LBS: [
      { Name: t('App.Lstatus_LBS_Closed'), value: 1 },
      { Name: t('App.Lstatus_LBS_Open'), value: 2 },
    ],

    componentFunction: [
      { Name: t('App.L_componentFunction_main'), value: 1 },
      { Name: t('App.L_componentFunction_Isolation'), value: 2 },
      { Name: t('App.L_componentFunction_sub'), value: 3 },
      { Name: t('App.L_componentFunction_link'), value: 4 },
    ],

    capacity_Fuse: [
      { Name: t('App.L_Capacity_Unknown'), value: -1 },
      { Name: '10A', value: 10 },
    ],
    typeSearch: [
      { Name: t('App.L_Search_by_number'), value: 1 },
      { Name: t('App.L_location_search'), value: 2 },
      { Name: t('App.L_component_type_search'), value: 3 },
    ],
  };
  elementNames=Object.assign(elementNames, Names);



}

function valueText(objName, value,key) {
  let value_key = null;

  elementNames[objName].some((v, index) => {
    if (v.value == parseInt(value)) {
      value_key = v[key];

      return true;
    }
  });
  if (value_key != null) {
    return value_key;
  }
}

export { elementNames, initialization, valueText };
