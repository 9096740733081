let lg = {
  App: {
    L_feeders_Selected: 'المغذي',
    L_feeder: 'مغذي',
    L_componentsTypes_transformer: 'محول',
    L_componentsTypesLoadBreakSwitch: 'سكينة هوائية',
    L_componentsTypesMediumVoltageFuse: 'فيوز',
    L_componentsTypes_Sectionalizer: 'مجزئ',
    L_componentsTypesRingMainUnit: 'وحدة توزيع حلقية',
    L_componentsTypes_Recloser: 'معيد',
    L_componentsTypes_voltageRegulator: 'منظم جهد',

    L_modes_LBS_normally_closed: 'الطبيعي مغلق',
    L_modes_LBS_normally_open: 'الطبيعي مفتوح',
    L_modes_LBSSelected: 'اختر الوضع',

    Lstatus_LBS_Open: 'مفتوح',
    Lstatus_LBS_Closed: 'مغلق',
    L_status_LBSSelected: 'اختر الحالة',

    L_componentFunction_main: 'رئيسية',
    L_componentFunction_Isolation: 'عزل',
    L_componentFunction_sub: 'تفريعة',
    L_componentFunction_link: 'ربط',

    L_associatedNotcomponent: 'لا يوجد رابط',
    L_associated_with_component: 'مرتبط مع عنصر',
    L_associated_with_componentSelected: 'اختر العنصر ',

    L_connect_with_component: 'ربط مع المغذي',
    L_kml: 'KML',
    L_component_bound_meters: 'العدادات المرتبطة بالعنصر',

    L_Meter_not_valid: 'رقم العداد غير صحيح',

    L_bound_meter: 'ربط عداد',
    L_meter_bound_info: 'كن قريبا من مكان العداد ثم قم بالضغط على زر ربط عداد',
    L_Selected_capacity_Fuse: 'سعة الفيوز',

    L_Added_successfully: 'تمت الاضافة بنجاح',
    L_Edited_successfully: 'تم التعديل بنجاح',
    L_Failed_add: 'فشل في الإضافة',

    L_Function: 'اختر الوظيفة',

    L_Name_already_exists: 'يوجد عنصر يحمل نفس الرقم',

    L_type_of_search: 'اختر نوع البحث',
    L_Search_by_number: 'بحث بالرقم',
    L_location_search: 'بحث بالمكان',

    L_distance: 'المسافة',
    L_km: 'كم',
    L_delete: 'حذف',
    L_edit: 'تعديل',
    L_Action: 'الاجراء',

    L_component_type_search: 'بحث بنوع العنصر',

    L_Selected_least_one_component: 'اختر عنصر واحد على الاقل',

    L_location: 'المكان',

    L_meter_has_bound_connected_before: 'تم ربط العداد بالمحول من قبل',

    L_Confirm_deletion: 'هل تريد حذف العنصر ؟',
    L_Yes: 'نعم',
    L_No: 'لا',
    L_Create_account: 'انشئ حساب',

    L_language: 'اللغة',
    L_title_Search: 'بحث',
    L_button_Search: 'بحث',
    L_Enter_account: 'الحساب,الاشتراك,العداد,التجهيز',
    L_Installation: 'التجهيز',
    L_Close: 'إغلاق',
    L_maxDigits: 'يجب أن يحتوي حقل رقم الحساب على 11 رقمًا بالضبط',
    L_Subscription: 'الاشتراك',
    L_MeterNumber: 'رقم العداد',
    L_Breaker: 'القاطع',
    L_BreakerSelected: 'اختر سعة القاطع',
    L_ContractAccount: 'رقم الحساب',
    L_Coordinates: 'إحداثيات',
    L_NoData: 'لاتوجد بيانات',
    L_windowOpen: 'افتح في الخرائط',
    L_searchField: 'حقل البحث',
    L_accuracy: 'الدقة',
    L_getCoordinates: 'طلب تحديد الموقع الجغرافي',
    L_PERMISSION_DENIED: 'رفض المستخدم طلب تحديد الموقع الجغرافي.',
    L_POSITION_UNAVAILABLE: 'معلومات الموقع غير متوفرة.',
    L_TIMEOUT: 'انتهت مهلة طلب الحصول على موقع المستخدم.',
    L_UNKNOWN_ERROR: 'حدث خطأ غير معروف.',
    L_IsNotSupported: 'لا يدعم هذا المتصفح تحديد الموقع الجغرافي.',
    L_ServerDownView: 'الصفحة غير موجودة',
    // --------------------------------------------------------------
    L_Mobile_number: 'رقم الجوال',
    L_Enter_Mobile: 'أدخل رقم الجوال',
    L_title_SignUpView: 'الاشتراك',
    L_Enter_username: 'أدخل الاسم',
    L_username: 'اسم االمستخدم',
    L_button_SignUp: 'اشتراك',
    L_title_Unauthorized: 'غير مصرح',
    L_401: 'غير مصرح بالوصول',
    L_LoginView: 'تسجيل الدخول',
    L_password: 'كلمه السر',
    L_Enter_password: 'أدخل كلمة المرور',
    L_passwordConfirmation: 'تأكيد كلمة المرور',
    L_Enter_passwordConfirmation: 'أدخل تأكيد كلمة المرور',
    L_passwords_not_match: 'كلمات السر الخاصة بك لا تتطابق.',
    L_Mobile_not_valid: 'رقم الجوال غير صحيح',
    L_button_Login: 'تسجيل الدخول',
    L_title_feeder: 'المغذيات',
    L_Name: 'الاسم',

    L_components: 'عدد العناصر [N]',
    L_Show: 'عرض',
    L_Capacity: 'السعة',
    L_Capacity: 'السعة [KVA]',
    L_meters: 'العدادات',
    L_error_connection: 'حدث خطأ أثناء الاتصال',
    L_Account_created: 'تم إنشاء الحساب بنجاح',
    L_title_transformers: 'المعدات',
    L_User_already_exists: 'حساب المستخدم موجود بالفعل',
    L_title_Users: 'المستخدمون',
    L_IsActivated: 'تفعيل المستخدم',
    L_password_weak: 'كلمة المرور ضعيفة',
    L_Successfully_logged: 'تم تسجيل الدخول بنجاح',
    L_Wrong_mobile_password: 'رقم الجوال أو كلمة المرور غير صحيحة',
    L_Already_account: 'هل لديك حساب؟ ',
    L_Enter_Full_Name: 'ادخل الاسم الثلاثي',
    L_User_permissions_successfully: 'تم تعديل أذونات المستخدم بنجاح',
    L_User_permissions_Failed: 'فشل تعديل أذونات المستخدم ',
    L_P_meter_Show: 'عرض العدادات',
    L_P_meter_Edit: 'تعديل العدادات',
    L_P_components_Show: 'عرض العناصر',
    L_P_components_Edit: 'تعديل العناصر',
    L_P_feeder_Show: 'عرض المغذيات',
    L_P_feeder_Edit: 'تعديل المغذيات',
    L_title_components: 'العناصر',

    L_feeder_Tree: 'Tree',
    L_outputVoltage_high: 'الجهد [K]',
    L_outputVoltage_low: 'الجهد',
    L_Component_type: 'نوع العنصر',
    L_transformer: 'محول',
    L_title_Dashboard: 'لوحة التحكم',
    L_title_Users_devices: 'اجهزة المستخدمين',
    L_device: 'الجهاز',
    L_title_meters: 'العدادات',
    L_title_meter_coordinate_update: 'تحديث الاحداثية',
    L_meter_Update_info: 'كن قريبا من مكان العداد ثم قم بالضغط على زر تحديث',
    L_Update_button: 'تحديث',
    L_WaitingAccuracyImprove: 'انتظار تحسن الدقة',

    L_feeders_Selected_All: 'الكل',
    L_permission: 'الصلاحية',
    L_Status: 'الحالة',
    L_save: 'حفظ',

    L_meterSearch: 'البحث عن عدادات',
    L_meterAdd: 'إضافة بيانات عداد',
    L_meterEdit: 'تحرير بيانات عداد',

    L_meterUpdateLocation: 'تحديث بيانات موقع العداد',
    L_metersDisplay: 'عرض جميع العدادات',
    L_meterRelationship: 'ربط العداد بالمحول',

    L_feederDisplay: 'عرض كل المغذيات',
    L_feederEdit: 'تحرير بيانات المغذي',
    L_feederAdd: 'إضافة بيانات المغذي',
    L_componentDisplay: 'عرض جميع العناصر',
    L_componentEdit: 'تحرير بيانات عنصر',
    L_componentAdd: 'إضافة بيانات عنصر',
    L_componentsDel: 'حذف بيانات عنصر',
    L_Cannot_delete: 'لا يمكن الحذف العنصر مستخدم',
    L_signOut: 'تسجيل الخروج',

    L_Selected_type_component: 'اختر نوع العنصر ',
    L_Capacity_Unknown: 'غير معروفة',
    L_Selected_capacity_transformer: 'اختر سعة المحول',
    L_kVA: 'ك.ف.ا',
    L_Voltage: 'فولت',
    L_Ampere: 'امبير',
    L_lowVoltageSelected: 'اختر الجهد',

    L_ComponentsButtonAdd: 'إضافة عنصر',
    // L_ComponentsButtonAdd: 'إضافة',
    L_associatedWithEquipment: 'مرتبط معنصر',
    L_description: 'الوصف',
    L_componentName: 'الرقم',
    L_componentName_Enter_numbers_only: 'الادخال ارقام موجبة فقط',
    L_Latitude: '[N] خط العرض',
    L_Longitude: '[E] خط الطول',
    L_coordinate_request: 'Gps',
    L_coordinate_incorrect: 'الاحداثية غير صحيحة',

    L_Edit_user_permissions: 'تعديل صلاحيات المستخدم',
    L_location_meter_successfully_updated: 'تم تحديث مكان العداد بنجاح',
    L_Failed_update_location_meter: 'فشل  تحديث مكان العداد ',
    L_meter_location_was_updated: 'موقع العداد محدث سابقا',

    L_title_change_password: 'تغير كلمة المرور',
    L_Enter_OldPassword: 'أدخل كلمة المرور القديمة',

    L_Enter_NewPassword: 'أدخل كلمة المرور جديدة',
    L_Enter_NewPasswordConfirmation: 'أدخل تأكيد كلمة المرور الجديدة',

    L_old_password_incorrect: 'كلمة المرور غير صحيحة',
    L_Password_changed_successfully: 'تم تغير كلمة المرور بنجاح',

    L_title_statistics: 'احصائيات',
    L_components_count: 'عدد العناصر المدخلة',
    L_meters_count: 'عدد العدادات المدخلة',
    L_Total: 'المجموع',

    L_title_MeterDataUpdate: 'تحديث بيانات العدادات',

    L_Address: 'العنوان',

    L_Al_Shamly: 'الشملي',
    L_Hafeera: 'الحفيرة',
    L_asbatar: 'اسبطر',
    L_eamayir_bn_sanea: 'عمائر بن صنعاء',
    L_West_Shamli: 'غرب الشملي',
    L_alfayda: 'الفيضة',
    L_hafirat_alsabea: 'حفيرة السبعة',
    L_East_Shamli: '',

    L_libada: 'لبدة',
    L_Al_Bidi: 'البيدي',
    L_Al_bardan: 'البردان',
    L_Al_mase: 'المصع',
    L_Dharghat: 'ضرغط',
    L_Al_shaqa: 'الشقة',

    L_nothing: '',
    L_market: 'السوق',
    L_Shamly: 'الشملي',
    L_Deira: 'الديرة',
    L_altaeawun: 'التعاون',
    L_Al_Rayyan: 'الريان',
    L_alsinaeia: 'الصناعية',
    L_alnakhil_alsharqiu: 'النخيل الشرقي',
    L_alrafiea: 'الرفيعة',
    L_alnakhil_shamal: 'النخيل شمال',
    L_alnakhil_janub: 'النخيل جنوب',

    L_rawdat_altinihat: 'روضة التنهات',
    L_mukhatat_aleawda: 'مخطط العودة',
    L_hafayir_aleahin: 'حفائر العهين',
    L_dafayin_aldaba: 'دفائن الضباء',
    L_bir_alrafdi: 'بير الرفدي',
    L_muhayirat_dhuayra: 'محيرة ذويرة',
    L_albuaytan_naeimin: '[البويطن] [نعيمين]',
    L_ghizlana: 'غزلانة',
    L_am_nakhlat_qumraa: '[ام نخلة] [قمرى]',
    L_almazarie: 'المزارع',
    L_tafhan: 'طفحان',
    L_qalib_alatrim: 'قليب الاطرم',
    L_bayda_alnathil: 'بيضاء النثيل ',
    L_alabitar: 'الابيتر',
    L_sahot: 'ساحوت',
    L_alzabya: 'الظبية',
    L_mushid: 'مشيد',
    L_safia: 'صفية',
    L_alhafirat_algharbia: 'الحفيرة الغربية',
    L_mazarie_alhania: 'مزارع الحنية',
    L_almukhatat: 'المخطط',
    L_alqadima: 'القديمة',
    L_aljadiduh: 'الجديده',
    L_badayie_bn_shitywiin: 'بدائع بن شتيوي',
    L_mubriz: 'مبرز',
    L_bir_bin_manwa: 'بير بن منوة',
    L_musharifa: 'مشرفة',
    L_vip: 'كبار مشتركين',

    L_coordinate_sharing: 'مشاركة الاحداثية',

    L_data_incorrect: 'البيانات المدخلة غير صحيحة',

    L_Search_result_for: 'نتيجة البحث عن',
    L_Update_subscriptions: 'تحديث الاشتراكات',
    L_meter_coordinates_updated_count: 'عدد العدادات المحدث موقعها',

    L_Update_all_data: 'تحديث جميع البيانات',
    L_Update_coordinates_installation: 'تحديث الاحداثيات برقم التركيب',
    L_title_Search_history: 'سجل البحث',

    L_field: 'حقل البحث',
    L_search_data: 'بيانات البحث',
    L_time: 'الوقت',
    L_Delete_login_history: 'حذف',

    L_day_name_0: 'الأحد',
    L_day_name_1: 'الاثنين',
    L_day_name_2: 'الثلاثاء',
    L_day_name_3: 'الأربعاء',
    L_day_name_4: 'الخميس',
    L_day_name_5: 'الجمعة',
    L_day_name_6: 'السبت',

    L_date: 'التاريخ',
    L_day: 'اليوم',
    L_searches_count: 'عدد عمليات البحث',
    L_Download_regions_file: 'تنزل ملف المناطق',
  },
};

export default lg;
