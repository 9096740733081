<template>

      <div id="LoadingView" class="bg-body">
            <div
              class="overlay d-flex justify-content-center align-items-center"
            >
              <div class="">
              <!-- {{$store.getters.showLoading}} -->
                <div

                  class="spinner-grow text-primary"
                  role="status"
                  style="width: 3rem; height: 3rem; z-index: 20"
                >
                  <span class="sr-only"></span>
                </div>
              </div>
            </div>
          </div>
 
</template>

<script>

import store from '@/store.js';


export default {};
</script>
<style scoped>
/* Override default value of 'none' */


#LoadingView{
width: 100%;
height:  100%;
	position: fixed;
 z-index: 999999999;
 /* background-color: rgb(255, 255, 255); */
 	top: 0;
	right: 0;
  opacity: 0.1;
}


.overlay {
  /* background-color: #dee4ec; */
  position: absolute;
  left: 50%;
  top: 50%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  /* opacity: 0; */
  /* filter: alpha(opacity=1); */
  z-index: 9999999999;
}
</style>